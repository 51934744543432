import React from 'react'
import { motion } from 'framer-motion'
import { FC } from 'react'
import { Language } from './Body'
import { TextProps } from './Text'
import { IoLogoLinkedin } from 'react-icons/io5'

const Footer: FC<TextProps> = ({ showText, language }) => {
  const textVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 2, duration: 1.5 },
    },
    hidden: {
      opacity: 0,
      transition: { delay: 0.5 },
    },
  }

  return (
    <motion.div
      className='flex justify-between content-end sm:m-8 m-4 text-white text-xs sm:text-base'
      variants={textVariants}
      initial={'hidden'}
      animate={showText ? 'visible' : 'hidden'}
    >
      <div className='flex flex-row items-end'>
        <a
          href='https://www.linkedin.com/in/albin-bergstr%C3%B6m-5069b649/'
          className='hover:cursor-pointer'
        >
          <IoLogoLinkedin
            className='w-6 h-6 sm:w-9 sm:h-9 m-1 -ml-2 sm:mr-3 hover:cursor-pointer
          transition-all hover:w-11 hover:h-11 hover:m-0 hover:mr-2 hover:-ml-3 hover:rotate-90'
          />
        </a>
        <div>
          {language === Language.Swedish ? (
            <p className='text-left'>
              <a href='mailto:hej@divinepine.io'>hej@divinepine.io</a>
              <br />
              070-277 87 14
            </p>
          ) : (
            <p className='text-left'>
              <a href='mailto:hello@divinepine.io'>hello@divinepine.io</a>
              <br />
              +46 70-277 87 14
            </p>
          )}
        </div>
      </div>
      <p className='text-right'>
        Divine Pine
        <br />
        Drottninggatan 55
        <br />
        Norrköping 602 32
      </p>
    </motion.div>
  )
}

export default Footer
