import React from 'react'
import Body from '../components/Body'

const Home = () => {
  return (
    <main className='absolute inset-0 overflow-hidden'>
      <Body />
    </main>
  )
}

export default Home
