import React from 'react'
import { animate, motion, useMotionValue } from 'framer-motion'
import { FC, useEffect, useRef, useState } from 'react'

interface ButtonInterface {
  showText: boolean
  updateState: () => void
}

const Button: FC<ButtonInterface> = ({ showText, updateState }) => {
  const buttonColor = useMotionValue('#000')
  const buttonTextColor = useMotionValue('#fff')
  const buttonAttention = useMotionValue('0px 0px 0px 0px #000')

  // If the button is not pressed within 12 seconds after page load the button
  // will do a pulsing animation to hint to the user that it can be interacted with.
  const [buttonPressedOnce, setButtonPressedOnce] = useState(false)
  const intervalRef = useRef(0)
  useEffect(() => {
    const id = window.setInterval(() => {
      animate(
        buttonAttention,
        [
          '0px 0px 0px 0px #000',
          '0px 0px 0px 20px #000',
          '0px 0px 0px 0px #000',
          '0px 0px 0px 20px #000',
          '0px 0px 0px 0px #000',
          '0px 0px 0px 20px #000',
          '0px 0px 0px 0px #000',
        ],
        { duration: 2.5, times: [0, 0.15, 0.3, 0.45, 0.6, 0.75, 1] },
      )
    }, 12000)
    intervalRef.current = id
    return () => {
      // Remove interval when component unloads
      clearInterval(intervalRef.current)
    }
  }, [])

  // The button pulsing animation is cancelled once the user presses the button once.
  useEffect(() => {
    if (buttonPressedOnce) {
      clearInterval(intervalRef.current)
    }
  }, [buttonPressedOnce])

  useEffect(() => {
    // Run button color animations on state change
    if (showText) {
      animate(buttonColor, '#fff', { duration: 0.2, delay: 0.2 })
      animate(buttonTextColor, '#000', { duration: 0.2, delay: 0.2 })
    } else {
      animate(buttonColor, '#000', { duration: 0.3, delay: 1 })
      animate(buttonTextColor, '#fff', { duration: 0.3, delay: 1 })
    }

    if (!buttonPressedOnce && showText) {
      setButtonPressedOnce(true)
    }
  }, [showText])

  // Animate between the two button icons ("?" and "X")
  const iconVariants = {
    draw: {
      pathLength: 1,
      transition: {
        duration: 0.5,
        delay: 0.2,
      },
    },
    unDraw: {
      pathLength: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  const iconProps = {
    stroke: buttonTextColor,
    strokeWidth: 0.5,
    fill: 'none',
  }

  return (
    <motion.button
      className='bg-black text-white rounded-full
      sm:p-6 sm:m-6 sm:hover:p-8 sm:hover:m-4 active:sm:p-6 active:sm:m-6
      p-4 m-4 !active:hover:p-6 !active:hover:m-6
      transition-all'
      style={{
        backgroundColor: buttonColor,
        color: buttonTextColor,
        boxShadow: buttonAttention,
      }}
      onClick={updateState}
    >
      <svg
        width='30'
        height='30'
        version='1.1'
        viewBox='0 0 7.938 7.938'
        xmlns='http://www.w3.org/2000/svg'
      >
        <motion.path
          d='m1.058 6.879 5.821-5.821'
          {...iconProps}
          variants={iconVariants}
          initial={'unDraw'}
          animate={showText ? 'draw' : 'unDraw'}
        />
        <motion.path
          d='m1.058 1.058 5.821 5.821'
          {...iconProps}
          variants={iconVariants}
          initial={'unDraw'}
          animate={showText ? 'draw' : 'unDraw'}
        />
        <motion.path
          d='m3.966 6.651c-0.1773 0.00121-0.3364 0.1567-0.3352 0.334 0.00121 0.1773 0.1421 0.3328 0.3352 0.3352 0.1931 0.002427 0.34-0.17 0.3376-0.3364-0.002427-0.1664-0.1494-0.3364-0.3376-0.3328z'
          {...iconProps}
          variants={iconVariants}
          initial={'unDraw'}
          animate={showText ? 'unDraw' : 'draw'}
        />
        <motion.path
          d='m3.982 5.44c-0.009619-1.494 1.671-1.689 1.706-3.115 0.002944-1.101-0.8113-1.784-1.688-1.782-1.107 0.002972-1.747 0.8809-1.748 1.734-0.002022 1.015 0.7762 1.31 0.7762 1.31'
          {...iconProps}
          variants={iconVariants}
          initial={'unDraw'}
          animate={showText ? 'unDraw' : 'draw'}
        />
      </svg>
    </motion.button>
  )
}

export default Button
