import React from 'react'
import { FC } from 'react'

const SingleWave: FC = () => {
  return (
    <div className='flex flex-col'>
      <svg
        className='relative bottom-0 w-full sm:h-24 h-12 -mb-2'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 24 150 28'
        preserveAspectRatio='none'
        shape-rendering='auto'
      >
        <defs>
          <path
            id='gentle-wave'
            d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
          />
        </defs>
        <g className='parallax'>
          <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(0,0,0,0.7)' />
          <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(0,0,0,0.5)' />
          <use xlinkHref='#gentle-wave' x='48' y='5' fill='rgba(0,0,0,0.3)' />
          <use xlinkHref='#gentle-wave' x='48' y='7' fill='#000' />
        </g>
      </svg>
      <div className='bg-black w-full h-screen'></div>
    </div>
  )
}

export default SingleWave
