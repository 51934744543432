import React from 'react'
import { animate, motion, useMotionValue } from 'framer-motion'
import { FC, useEffect, useRef } from 'react'

interface DivineTitleProps {
  setHeaderHeight: (value: number) => void
}

const DivineTitle: FC<DivineTitleProps> = (props) => {
  const globalAnimationDelay = 0.6

  const slideFromAbove = useMotionValue(-window.innerHeight / 2)
  const slideFromLeft = useMotionValue(-window.innerWidth)
  const slideFromRight = useMotionValue(window.innerWidth)
  const opacityTitle = useMotionValue(0)
  const opacitySubTitle = useMotionValue(0)

  animate(slideFromAbove, 0, { duration: 2, delay: globalAnimationDelay })
  animate(slideFromLeft, 0, { duration: 2, delay: globalAnimationDelay + 1 })
  animate(slideFromRight, 0, { duration: 2, delay: globalAnimationDelay + 1 })
  animate(opacityTitle, 1, { duration: 1, delay: globalAnimationDelay + 1.5 })
  animate(opacitySubTitle, 1, {
    duration: 1,
    delay: globalAnimationDelay + 2.4,
  })

  const headerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    props.setHeaderHeight(
      headerRef.current ? headerRef.current.clientHeight : 0,
    )
  }, [])

  return (
    <div id='title' className='text-center p-6 font-semibold' ref={headerRef}>
      <motion.h1
        className='sm:text-6xl text-4xl '
        style={{ y: slideFromAbove, opacity: opacityTitle }}
      >
        Divine Pine
      </motion.h1>
      <div className='flex justify-center'>
        <motion.div
          className='sm:text-2xl text-1x1'
          style={{ x: slideFromLeft, opacity: opacitySubTitle }}
        >
          Software&nbsp;&nbsp;
        </motion.div>
        <motion.div
          className='sm:text-2xl text-1x1'
          style={{ x: slideFromRight, opacity: opacitySubTitle }}
        >
          development
        </motion.div>
      </div>
    </div>
  )
}

export default DivineTitle
