import { motion } from 'framer-motion'
import React from 'react'
import { FC } from 'react'

export interface AnimationProps {
  animationDuration?: number
  animationDelay?: number
}

const DivineLogo: FC<AnimationProps> = ({
  animationDuration = 2,
  animationDelay = 0,
}) => {
  const logoProps = {
    stroke: 'black',
    strokeWidth: 2.5,
    strokeLinecap: 'round' as any,
    fill: 'none',
    pathLength: 0,
    animate: {
      pathLength: 1,
    },
    transition: { duration: animationDuration, delay: animationDelay },
  }

  return (
    <svg
      height='200mm'
      version='1.1'
      viewBox='0 0 93.22 144.4'
      xmlns='http://www.w3.org/2000/svg'
      className='w-full sm:max-h-[30rem] max-h-[25rem] px-6'
    >
      <g transform='translate(-55 -25.26)'>
        <motion.path
          {...logoProps}
          d='m101.7 166.6v-97.98l-42.46 42.46h32.66l-16.33 16.33h16.33'
        />
        <motion.path {...logoProps} d='m111.5 85 26.13 26.13h-26.13' />
        <motion.path
          {...logoProps}
          d='m68.86 90.53s-6.394-8.386-6.394-21.45c0-13.06 9.798-39.19 39.19-39.19 29.39 0 39.19 26.13 39.19 39.19 0 13.06-6.532 22.86-9.798 26.13'
        />
      </g>
    </svg>
  )
}

export default DivineLogo
