import React from 'react'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import { FC, useEffect } from 'react'
import { Language } from './Body'

export interface TextProps {
  showText: boolean
  language: Language
}

const Text: FC<TextProps> = ({ showText, language }) => {
  const x = useMotionValue(0.0)

  const generateGradientSections = (opacity: number, sections: number) => {
    let gradient = 'linear-gradient(to top,'
    for (var i = 1; i <= sections; i++) {
      gradient = gradient.concat(
        `rgba(255, 255, 255, ${Math.round(opacity * (sections / i))})`,
      )
      if (i < sections) gradient = gradient.concat(',')
    }
    return gradient.concat(')')
  }

  useEffect(() => {
    if (showText) {
      animate(x, 1, { duration: 1.8, delay: 0.7 })
    } else {
      animate(x, 0, { duration: 0.9, delay: 0.0 })
    }
  }, [showText])

  const gradient = useTransform(x, (v) => [
    generateGradientSections(0, 25),
    generateGradientSections(v, 25),
  ])

  return (
    <motion.div className='text-center w-screen  justify-center'>
      <motion.p
        className='font-bold mx-auto sm:max-w-xl p-4
      sm:text-[1.25rem] text-[0.95rem] bg-clip-text text-transparent'
        style={{ backgroundImage: gradient }}
      >
        {language === Language.Swedish ? (
          <>
            Divine Pine skapar digitala lösningar efter era specifikationer
            eller hjälper er med pågående projekt inom den digitala domänen.
            <br />
            <br />
            Tveka inte att höra av dig på{' '}
            <a href='mailto:hej@divinepine.io'>hej@divinepine.io</a>!
          </>
        ) : (
          <>
            Divine Pine is a software design/development company that creates
            digital solutions from your specification or helps you with ongoing
            projects in the digital domain.
            <br />
            <br />
            Feel free to reach out to{' '}
            <a href='mailto:hello@divinepine.io'>hello@divinepine.io</a>!
          </>
        )}
      </motion.p>
    </motion.div>
  )
}

export default Text
