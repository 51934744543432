import React from 'react'
import { animate, motion, useMotionValue } from 'framer-motion'
import { FC, useEffect } from 'react'
import SingleWave from './SingleWave'

interface DivineWaveProps {
  showText: boolean
  headerHeight: number
}

const DivineWave: FC<DivineWaveProps> = ({ showText, headerHeight }) => {
  // Get wave height as a percentage of the body's height
  const getWaveHeight = (percentage: number, headerHeight: number = 0) => {
    return window.innerHeight * (1.0 - percentage) + headerHeight
  }

  // Animation constants
  const closedHeight = getWaveHeight(0.25)
  const openedHeight = getWaveHeight(1.025, headerHeight)
  const closedOpacity = 0.5
  const openedOpacity = 0.92
  const opacity = useMotionValue(closedOpacity)

  // Initialize animation variables
  const waveHeight = useMotionValue(getWaveHeight(-1.0))

  // Run animations on parent state change
  useEffect(() => {
    if (showText) {
      // Animate "fluid" to rise and get higher opacity
      animate(opacity, openedOpacity, { duration: 2 })
      animate(waveHeight, openedHeight, { duration: 2 })
    } else {
      // Animate "fluid" to lower and get lower opacity
      animate(opacity, closedOpacity, { duration: 2 })
      animate(waveHeight, closedHeight, { duration: 2 })
    }
  }, [showText])

  // Create initial wave animation, which is a little bit slower
  useEffect(() => {
    animate(waveHeight, closedHeight, { duration: 5 })
  }, [])

  return (
    <motion.div
      style={{
        y: waveHeight,
        opacity: opacity,
      }}
    >
      <SingleWave />
    </motion.div>
  )
}

export default DivineWave
