import React from 'react'
import { useState } from 'react'
import Button from './Button'
import DivineLogo from './DivineLogo'
import DivineTitle from './DivineTitle'
import DivineWave from './DivineWave'
import Footer from './Footer'
import Text from './Text'

export enum Language {
  English,
  Swedish,
}

const Body = () => {
  const getBrowserLanguage = (): Language => {
    return navigator.language.search('sv') === -1
      ? Language.English
      : Language.Swedish
  }

  const [showText, setShowText] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [language, setLanguage] = useState(getBrowserLanguage())

  return (
    <div className='font-comfortaa flex-col items-center'>
      <DivineTitle setHeaderHeight={setHeaderHeight} />
      <div id='text' className='z-20 absolute sm:my-10 my-[3.5rem]'>
        <Text showText={showText} language={language} />
      </div>
      <div id='logo' className='-z-10 relative'>
        <DivineLogo />
      </div>
      <div id='button' className='z-20 relative text-center'>
        <Button
          showText={showText}
          updateState={() => setShowText((showText) => !showText)}
        />
      </div>
      <div id='wave' className='z-0 absolute top-0 w-full'>
        <DivineWave showText={showText} headerHeight={headerHeight} />
      </div>
      <div id='footer' className='z-10 absolute bottom-0 w-full'>
        <Footer showText={showText} language={language} />
      </div>
    </div>
  )
}

export default Body
